<script>
export default {
    name: 'IdeFileBrowser',

    components: {
        'file-type-icon': () => import('@/components/ui/FileTypeIcon.vue'),
    },

    props: {
        filePaths: {
            type: Array,
            default: () => ([]),
        },
    },

    computed: {
        hierarchy() {
            return this.filePaths.reduce((hier, path) => {
                let x = hier;
                path.split('/').forEach((item) => {
                    if (!x[item]) {
                        x[item] = {};
                    }
                    x = x[item];
                });
                x.path = path;
                return hier;
            }, {});
        },
    },

    mounted() {
        this.fileBrowserTree = document.querySelector('.file-browser-tree');
    },

    methods: {
        handleOpenFile(evt) {
            const allFiles = this.fileBrowserTree.querySelectorAll('.file-name');
            for (let i = 0; i < allFiles.length; i += 1) {
                allFiles[i].classList.remove('active');
            }
            evt.target.classList.add('active');
            this.$emit('open-file', evt.target.dataset.path);
        },

        handleFolderClick(evt) {
            evt.stopImmediatePropagation();
            evt.target.parentElement.classList.toggle('open');
        },
    },

    render(createElement) {
        const createFileIcon = (filePath) => {
            const fileName = filePath.split('/').pop();
            const fileType = fileName.split('.').pop();
            return createElement('file-type-icon', {
                props: {
                    name: fileType,
                },
            });
        };

        const createFile = ([hierarchyKey, hierarchyValue], pathPadding) => createElement('span', {
            class: 'file-name',
            style: `padding-left: ${pathPadding * 0.9375}rem`,
            attrs: {
                'data-path': hierarchyValue.path,
            },
            on: {
                click: this.handleOpenFile,
            },
        }, [
            createFileIcon(hierarchyValue.path),
            hierarchyKey,
        ]);

        const createTree = ([hierarchyKey, hierarchyValue], pathPadding) => {
            let res;
            if (hierarchyValue.path) {
                res = createFile([hierarchyKey, hierarchyValue], pathPadding);
            } else {
                res = createElement('div', {
                    class: 'folder',
                    on: {
                        click: this.handleFolderClick,
                    },
                }, [
                    createElement('span', {
                        class: 'folder-name',
                        style: `padding-left: ${pathPadding * 0.9375}rem;`,
                    }, [
                        createElement('file-type-icon', {
                            props: {
                                name: 'folder',
                                className: 'closed',
                            },
                        }),
                        createElement('file-type-icon', {
                            props: {
                                name: 'folder-opened',
                                className: 'open',
                            },
                        }),
                        hierarchyKey,
                    ]),
                    createElement('div', {
                        class: 'folder-content',
                    }, [
                        Object.entries(hierarchyValue)
                            .map(([folderHierarchyKey, folderHierarchyValue]) => createTree([
                                folderHierarchyKey,
                                folderHierarchyValue,
                            ], pathPadding + 1)),
                    ]),
                ]);
            }
            return res;
        };

        return createElement('div', {
            class: 'file-browser-wrapper',
        }, [
            createElement('div', {
                class: 'file-browser-header',
            }, [
                createElement('div', {
                    class: 'file-browser-title-wrapper',
                }, [
                    createElement('span', {
                        class: 'title',
                    }, [
                        'Project Files',
                    ]),
                ]),
            ]),
            createElement('div', {
                class: 'file-browser-tree',
            }, [
                Object.entries(this.hierarchy)
                    .map(([hierarchyKey, hierarchyValue]) => createTree([
                        hierarchyKey,
                        hierarchyValue,
                    ], 1)),
            ]),
        ]);
    },
};
</script>

<style lang="scss">
.file-browser-wrapper {
    @apply flex;
    @apply bg-ideBackgroundColor;
    @apply flex-col;
}

.file-browser-header {
    height: 40px;
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply justify-between;
    @apply px-2;
    flex-shrink: 0;
    @apply border-b;
    @apply border-solid;
    @apply border-ideBorderColor;
}

.file-browser-title-wrapper .title {
    @apply font-normal;
    @apply text-sm;
    @apply text-gray-300;
}

.file-browser-controls {
    @apply flex;
    @apply flex-row;
}

.file-browser-controls .icon {
    @apply h-4;
    @apply w-4;
}

.file-browser-controls .icon > svg {
    fill: var(--ideInactiveItemColor);
}

.file-browser-controls .icon:hover > svg {
    fill: var(--ideActiveItemColor);
    @apply cursor-pointer;
}

.file-browser-controls .icon:not(:last-child) {
    @apply mr-2;
}

.file-browser-tree {
    @apply flex;
    @apply flex-col;
    @apply overflow-hidden;
    @apply overflow-y-auto;
}

.file-browser-tree .folder {
    @apply flex;
    @apply flex-col;
    flex-shrink: 0;
}

.file-browser-tree .folder > .folder-content {
    display: none;
}

.file-browser-tree .folder.open > .folder-content {
    @apply block;
}

.file-browser-tree .folder-name {
    @apply px-2;
    @apply py-0;
    @apply h-8;
    @apply flex;
    @apply items-center;
    @apply text-sm;
    @apply relative;
    @apply text-gray-500;
}

.file-browser-tree .folder-name:hover {
    @apply cursor-pointer;
    @apply text-gray-300;
    background-color: rgba(0, 0, 0, 0.1);
}

.file-browser-tree .folder-name .file-type-icon,
.file-browser-tree .file-name .file-type-icon {
    @apply h-4;
    @apply w-4;
    @apply mr-2;
    pointer-events: none;
}

.file-browser-tree .folder > .folder-name .file-type-icon.closed {
    @apply flex;
}

.file-browser-tree .folder > .folder-name .file-type-icon,
.file-browser-tree .folder.open > .folder-name .file-type-icon.closed {
    display: none;
}

.file-browser-tree .folder.open > .folder-name .file-type-icon.open {
    @apply flex;
}

.file-browser-tree .file-name {
    @apply px-1;
    @apply py-1;
    @apply flex;
    @apply items-center;
    @apply text-gray-500;
    @apply text-sm;
    @apply relative;
    flex-shrink: 0;
}

.file-browser-tree .file-name:before,
.file-browser-tree .folder-name:before {
    content: '';
    position: absolute;
    @apply absolute;
    @apply h-full;
    @apply left-0;
    @apply bg-accent;
    @apply opacity-0;
    @apply w-1;
}

.file-browser-tree .file-name:hover {
    @apply cursor-pointer;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    @apply text-gray-400;
}

.file-browser-tree .file-name.active {
    background-color: rgba(0, 0, 0, 0.1);
    @apply text-gray-400;
}

.file-browser-tree .file-name:hover:before,
.file-browser-tree .file-name.active:before,
.file-browser-tree .folder-name:hover:before {
    @apply opacity-100;
}
</style>
