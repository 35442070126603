<template>
    <div class="codeRoom">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="onBannerClose"
        />
        <nav-bar />
        <div class="codeRoom__container">
            <div class="codeRoom__container--left">
                <header class="codeRoom__header">
                    <div class="flex w-full justify-between items-center">
                        <h1 class="codeRoom__title">
                            Code room
                        </h1>
                        <button class="button"
                                type="button"
                                @click="done"
                        >
                            <font-awesome-icon icon="arrow-left" class="mr-2" />
                            Done
                        </button>
                    </div>
                    <div class="codeRoom__panel">
                        <p class="codeRoom__subtitle">
                            You can upload your project files here.
                            To upload project files for a programming
                            language <strong>select
                            </strong> the programming language
                            <strong>
                                below
                            </strong>. Note this is a <strong>
                                compulsory requirement
                            </strong>.
                            You can preview your code on the editor on right.
                        </p>
                        <p class="codeRoom__subtitle">
                            For each language,
                            you <strong>must</strong> either
                            select an <strong>entry file or </strong> provide
                            a <strong>command to run</strong>.
                        </p>
                        <p class="codeRoom__subtitle">
                            Your files <strong>
                                must not be empty
                            </strong>
                        </p>
                        <p class="codeRoom__subtitle">
                            File upload works on Chromium browsers and Mozilla Firefox
                        </p>
                    </div>
                </header>
                <div class="codeRoom__main">
                    <div class="codeRoom__runtimes">
                        <runtimes :on-runtime-change="setCurrentRuntime"
                                  class-name="runtimes"
                        />
                    </div>
                    <div v-if="filePaths.length > 0" class="runtime_setup">
                        <div class="form__control">
                            <label class="form__label">Entry File</label>
                            <select v-model.trim="selectedRuntime.entryFile"
                                    class="form__input"
                            >
                                <option v-for="path in filePaths"
                                        :key="path"
                                        :value="path"
                                >
                                    {{ path }}
                                </option>
                            </select>
                        </div>
                        <div class="form__control">
                            <label class="form__label">Or</label>
                        </div>
                        <div class="form__control">
                            <label class="form__label">Command</label>
                            <input ref="title"
                                   v-model.trim="selectedRuntime.command"
                                   class="form__input"
                                   autofocus
                                   placeholder="node app.js"
                            >
                        </div>
                    </div>

                    <div class="codeRoom__upload">
                        <div class="uploader">
                            <input id="dirInput"
                                   ref="dirInput"
                                   type="file"
                                   class="uploader__input"
                                   multiple
                                   directory
                                   webkitdirectory
                                   allowdirs
                                   @change="uploader"
                            >
                            <div class="uploader__image">
                                <img src="@/assets/img/folder.png">
                                <p v-if="isUploading">
                                    uploading....
                                </p>
                                <p v-else>
                                    Click here to upload your project files
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="filePaths.length > 0"
                 class="codeRoom__container--right"
            >
                <file-explorer :file-paths="filePaths"
                               @open-file="getFile"
                />
                <editor :code="file.content"
                        :mode="fileName"
                />
            </div>
            <div v-else
                 class="codeRoom__container--default"
            >
                <img src="@/assets/img/empty.png">
                <div>
                    <p>Nothing to show at the moment</p>
                    <p>please upload {{ currentRuntime }} project files</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FileExplorer from '@/components/IDE/FileExplorer.vue';
import Runtimes from '@/components/ui/Runtimes.vue';
import Editor from '@/components/IDE/Editor.vue';
import NavBar from '@/components/ui/Navbar.vue';
import Banner from '@/components/ui/Banner.vue';

export default {
    components: {
        FileExplorer,
        Runtimes,
        Editor,
        NavBar,
        Banner,
    },

    props: {
        courseId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            file: {
                name: '',
                content: '',
                runtime: '',
            },
            banner: {
                message: '',
                messageType: '',
                isBannerShown: false,
                timeout: null,
            },
            isUploading: false,
            fileName: '',
            isSelectOpen: false,
            selectedRuntime: {
                entryFile: null,
                command: null,
            },
        };
    },

    computed: {
        ...mapGetters('courses', [
            'filePaths',
            'files',
            'courses',
        ]),
        ...mapGetters('runtimes', [
            'currentRuntime',
            'runtimes',
        ]),
    },

    watch: {
        'selectedRuntime.entryFile': function fx() {
            this.updateRuntime().catch((ex) => this.renderBanner('error', ex.message || ex));
        },

        'selectedRuntime.command': function fx() {
            this.updateRuntime().catch((ex) => this.renderBanner('error', ex.message || ex));
        },
    },

    async created() {
        await this.getCourseProjectFiles(this.courseId);
        const course = this.courses.find((i) => i.id === this.courseId);
        if (course) {
            const runtime = course.runtimes.find((i) => i.name === this.currentRuntime);
            this.selectedRuntime = runtime || {
                entryFile: null,
                command: null,
                name: null,
            };
        }
    },
    async beforeDestroy() {
        clearTimeout(this.banner.timeout);
    },
    methods: {
        ...mapActions('courses', [
            'getCourseProjectFiles',
            'uploadCourseFiles',
            'modifyCourse',
            'fetchCourse',
        ]),

        ...mapActions('runtimes', {
            setCurrentRuntimeImpl: 'setCurrentRuntime',
        }),

        async updateRuntime() {
            const payload = {
                runtimes: [],
            };
            const course = this.courses.find((i) => i.id === this.courseId);
            if (!course) {
                await this.fetchCourse(this.courseId);
                await this.updateRuntime();
                return;
            }
            const runtime = course.runtimes.find((i) => i.name === this.currentRuntime) || {};
            runtime.name = this.currentRuntime;
            runtime.entryFile = this.selectedRuntime.entryFile || null;
            runtime.command = this.selectedRuntime.command || null;
            payload.runtimes = [
                runtime,
                ...course.runtimes.filter((i) => i.name !== runtime.name),
            ];
            await this.modifyCourse({ courseId: this.courseId, updates: payload });
        },

        renderBanner(type, message) {
            this.banner.messageType = type;
            this.banner.message = message;
            this.banner.isBannerShown = true;
            this.banner.timeout = setTimeout(
                () => { this.banner.isBannerShown = false; }, 3000,
            );
        },

        onBannerClose() {
            this.banner.isBannerShown = false;
            clearTimeout(this.banner.timeout);
        },

        async uploader() {
            try {
                this.isUploading = true;
                const input = this.$refs.dirInput;
                await this.uploadCourseFiles({
                    input,
                    opts: {
                        courseId: this.courseId,
                        runtime: this.currentRuntime,
                    },
                });
                this.isUploading = false;
                input.files = null;
                this.renderBanner('success', 'upload successful');
            } catch (ex) {
                this.isUploading = false;
                this.renderBanner('error', ex.message || 'upload failed');
            }
        },

        getFile(fileName) {
            this.file = this.files.find((file) => file.name === fileName);
            this.fileName = fileName;
        },

        setCurrentRuntime(runtime) {
            this.setCurrentRuntimeImpl(runtime);
            this.file = {
                name: '',
                content: '',
                runtime: '',
            };
            this.toggleDropdown();
        },

        toggleDropdown() {
            this.isSelectOpen = !this.isSelectOpen;
        },

        done() {
            if (this.filePaths.length === 0) {
                this.$router.go(-1);
                return;
            }
            const entryFileExists = this.selectedRuntime.entryFile;
            const commandExists = this.selectedRuntime.command;
            if (entryFileExists || commandExists) {
                this.$router.go(-1);
                return;
            }
            this.renderBanner(
                'error',
                'entry file or start command is required',
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.codeRoom{
    @apply w-screen;
    @apply h-auto;
}

.codeRoom__header{
    grid-area: header;
}

.codeRoom__main{
    grid-area: main;
    @apply mt-5;
    @apply flex;
    @apply flex-col;
}

.codeRoom__panel{
    @apply border-l-4;
    @apply border-solid;
    @apply border-accent;
    @apply mt-3;
    @apply font-medium;
    @apply text-sm;
    @apply p-5;
    @apply rounded-tr-md;
    @apply rounded-br-md;
    @apply text-gray-700;
    @apply bg-orange-100;
}

.codeRoom__title{
    @apply mb-5;
    @apply font-semibold;
    @apply text-3xl;
    @apply text-gray-800;
}

.codeRoom__subtitle{
    @apply text-gray-700;
    &:not(:last-of-type){
        @apply mb-5;
    }
}

.codeRoom__runtimes{
    @apply h-16;
    @apply w-64;
}

.codeRoom__upload{
    @apply flex-1;
    @apply flex;
    @apply justify-center;
    @apply items-start;
    @apply mt-6;
}

.codeRoom__container{
    @apply mt-16;
    @apply w-full;
    min-height: calc(100vh - 50px);
    @apply relative;
    &--left{
        @apply fixed;
        @apply left-0;
        top: 50px;
        width: 40vw;
        @apply bottom-0;
        @apply overflow-y-auto;
        @apply grid;
        grid-template-columns: 30px 1fr 30px;
        grid-template-rows: 40px auto 1fr;
        grid-template-areas:
                "columnStart start columnEnd"
                "columnStart header columnEnd"
                "columnStart main columnEnd";
        &::-webkit-scrollbar {
            width: 0.5em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            @apply bg-gray-300;
            background-color: var(--color-grey-03);
            outline: 1px solid #e2e8f0;
        }
    }
    &--right{
        @apply absolute;
        @apply right-0;
        @apply h-auto;
        @apply grid;
        width: 60vw;
        grid-template-columns: 300px 1fr;
        grid-template-rows: calc(100vh - 50px);
    }
    &--default{
        @apply absolute;
        right: 50%;
        top: 50%;
        @apply h-250;
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply w-250;
        @apply flex;
        @apply flex-col;
        @apply items-center;
        @apply justify-around;
        @apply rounded-md;
        @apply text-gray-700;
        transform: translateX(130%) translateY(-50%);
        & > img {
            height: 60%;
        }
        & > div {
            & > p {
                @apply text-gray-700;
                @apply text-center;
                @apply text-sm;
                @apply font-normal;
            }
        }
    }
}

.codeRoom__actions {
    @apply mt-8;
}

.runtimes{
    @apply h-full;
    @apply w-full;
    @apply border-r;
    @apply border-solid;
    @apply border-gray-300;
}

.runtime_setup {
    @apply flex;
    @apply flex-row;
    @apply justify-evenly;
    @apply p-4;
}

strong {
    @apply text-accent;
}

.uploader {
    @apply h-150;
    @apply rounded-md;
    @apply bg-white;
    @apply w-full;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-gray-700;
    @apply cursor-pointer;
    @apply relative;
    &:hover{
       @apply text-gray-900;
    }
    &__input {
        @apply absolute;
        z-index: 5;
        @apply top-0;
        @apply left-0;
        @apply h-full;
        @apply w-full;
        @apply cursor-pointer;
        @apply opacity-0;
    }
    &__image {
        @apply h-full;
        @apply flex;
        @apply flex-col;
        @apply items-center;
        @apply justify-center;
        & > img {
            height:60%;
        }
        & > p {
            @apply px-4;
            @apply font-medium;
            @apply text-sm;
            color: currentColor;
        }
    }
}
.button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    height: max-content;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}

</style>
