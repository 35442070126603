<template>
    <codemirror ref="editor"
                :value="code"
                :options="cmOptions"
                @input="onCmCodeChange"
    />
</template>

<script>
import {
    CodeMirror,
    codemirror,
} from 'vue-codemirror';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/dracula.css';

import 'codemirror/mode/meta';
import 'codemirror/mode/go/go';
import 'codemirror/mode/css/css';
import 'codemirror/mode/vue/vue';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/php/php';
import 'codemirror/mode/jsx/jsx';
import 'codemirror/mode/sass/sass';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/toml/toml';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/swift/swift';
import 'codemirror/mode/django/django';
import 'codemirror/mode/python/python';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/dockerfile/dockerfile';

export default {
    components: {
        codemirror,
    },
    props: {
        mode: {
            type: String,
            default: 'text/javascript',
        },
        code: {
            type: String,
            default: 'const a = 10',
        },
    },
    data() {
        return {
            content: this.code,
            cmOptions: {
                tabSize: 4,
                theme: 'dracula',
                lineNumbers: true,
                line: true,
                readOnly: true,
            },
        };
    },
    computed: {
        codemirror() {
            return this.$refs.editor.codemirror;
        },
    },
    watch: {
        mode(fileName) {
            if (CodeMirror) {
                const mode = CodeMirror.findModeByFileName(fileName);
                this.$refs.editor.codemirror.setOption('mode', mode.mime);
            }
        },
        code(code) {
            this.content = code;
        },
    },
    methods: {
        onCmCodeChange(newCode) {
            this.content = newCode;
        },
    },
};
</script>
